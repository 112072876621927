export const config = {
  title: "Stivers",
  company_logo: "stivers.png",
  logo_placements: ["Right Sleeve", "Left Chest"],
  show_modification_thumbnail: false,
  minimum_apparel_order: false,
  render_logo_preview: false,
  embroideries: {
    hat: [
      "Stivers",
      "Quicklane",
      "Ford",
      "Hyundai",
      "Chrysler",
      "Dodge",
      "Jeep",
      "Ram",
    ],
    mens: [
      "Stivers",
      "Quicklane",
      "Subaru",
      "Stivers Ford South",
      "Stivers Ford Birmingham",
    ],
    womens: [
      "Stivers",
      "Quicklane",
      "Subaru",
      "Stivers Ford South",
      "Stivers Ford Birmingham",
    ],
    accessory: [
      "Ford",
      "Hyundai",
      "Chrysler",
      "Dodge",
      "Jeep",
      "Ram",
      "Subaru",
    ],
    customs: [],
  },
  stores: [
    "Stivers Ford Montgomery, 4000 Eastern Blvd Montgomery, AL, 36116",
    "Stivers Ford Montgomery, 500 Palisades Blvd, Birmingham, AL, 35209",
    "Stivers Hyundai, 9950 Farrow Rd, Columbia, SC, 29203",
    "Stivers CDJR, 2209 Cobbs Ford Rd, Prattville, AL 36066",
    "Stivers Decatur Subaru, 1950 Orion Dr, Decatur, GA 30033",
    "Stivers Chevrolet, 111 Newland Road, Columbia, SC 29229",
    "Stivers Ford South Atlanta, 4355 Jonesboro Rd, Union City, GA 30291",
  ],
};
